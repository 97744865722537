import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import {
  getBaseUrl,
  isAlbumTemplateV8,
  shouldAllowSelections,
} from '../../common/utils';
import type AppStateManager from '../../viewerScriptHelpers/AppStateManager';
import type DataFetcher from '../../viewerScriptHelpers/DataFetcher';
import { isListOrListsPage } from '../../common/headerUtils';
import { initElementsInEditor, setPageSeo } from '@wix/albums-uou-common';
import { PAGE } from '../../consts/collectionsConsts';

const createController: CreateControllerFn = async ({
  flowAPI,
  appData,
}: ControllerParams) => {
  const {
    environment,
    translations: { t },
    controllerConfig,
  } = flowAPI;
  const { setProps, appParams, platformAPIs, wixCodeApi, compId, $w } =
    controllerConfig;
  const isSSR = environment.isSSR;

  const { baseUrls = {}, appDefinitionId, instance, instanceId } = appParams;

  const { appStateManager, dataFetcher } = appData as {
    appStateManager: AppStateManager;
    dataFetcher: DataFetcher;
  };

  const { isPreview, isEditor } = environment;
  const isViewer = environment.isViewer || environment.isSSR;
  const baseUrl = getBaseUrl(isViewer);

  const albumData: AlbumData = await dataFetcher.getAlbumData({
    baseUrl,
  });
  let windowRect = { width: 0, height: 0 };
  try {
    // @ts-expect-error
    if (wixCodeApi && wixCodeApi.window && wixCodeApi.window.getBoundingRect) {
      // @ts-expect-error
      const rectResult = (await wixCodeApi.window.getBoundingRect()) || {};
      windowRect = rectResult.window;
    }
  } catch (e) {}
  const { width = 0, height = 0 } = windowRect || {};
  const coverMaxSize = Math.max(width, height);
  let currentPageName: string | undefined;

  const isListsPage =
    wixCodeApi.location.path.length > 0 &&
    wixCodeApi.location.path[0].toLowerCase().includes('lists');

  const isSomeListPage =
    wixCodeApi.location.path.length > 0 &&
    wixCodeApi.location.path[0].toLowerCase().includes('list');

  const navigateToCollectionsPage = () => {
    !isPreview && wixCodeApi.location?.to && wixCodeApi.location.to('/lists');
  };
  const goToCollectionsPage = () => {
    if (!isListsPage) {
      navigateToCollectionsPage();
    }
  };

  wixCodeApi.user.onLogin(() => {
    appStateManager.setUserLoggedIn(true);
  });

  const initEditorElements = async (
    album: AlbumData | AlbumDataRefresh,
    isRefresh?: boolean,
  ) => {
    if (
      !isAlbumTemplateV8(
        (album as AlbumData).settings || (album as AlbumDataRefresh).albumData,
      )
    ) {
      return;
    }
    const pageName = currentPageName?.toLocaleLowerCase().replaceAll(' ', '');
    let collection: MediaCollection | any;
    if (pageName === 'list') {
      const collectionId = wixCodeApi.location.path[1];
      collection = await dataFetcher.getCollectionData({
        collectionId,
        baseUrl,
      });
    }
    return initElementsInEditor({
      page: pageName,
      $w,
      t,
      albumData: album,
      galleryId: wixCodeApi.location.path[0] || albumData.sets.gallery[0]?.id,
      collection: collection?.mediaCollection,
      visitorStatus: collection?.memberStatus,
      coverMaxSize,
    });
  };

  const getCollection = async (pageName: string | undefined) => {
    if (pageName !== PAGE.LIST) {
      return { mediaCollection: undefined, memberStatus: undefined };
    }
    const collectionId = wixCodeApi.location.path[1];
    const collection = await dataFetcher.getCollectionData({
      collectionId,
      baseUrl,
    });
    return collection;
  };

  const goToPage = (_controllerConfig: any) => (path: string) => {
    try {
      if (_controllerConfig.wixCodeApi.location.to) {
        _controllerConfig.wixCodeApi.location.to(path);
      }
    } catch (e) {
      console.error('albums uou, error navigating', e);
    }
  };

  if (
    isAlbumTemplateV8(albumData.settings) &&
    albumData.sets?.gallery?.length === 1 &&
    albumData.settings.template.coverPageName === 'sets' &&
    wixCodeApi.location.path.length === 0 &&
    wixCodeApi.location.to
  ) {
    wixCodeApi.location.to('/set/' + albumData.sets?.gallery[0].id);
  }

  return {
    async pageReady() {
      currentPageName = wixCodeApi.site.currentPage?.name;
      // TODO create new type fro convertedAlbumData
      if (isAlbumTemplateV8(albumData.settings)) {
        const pageName = currentPageName
          ?.toLocaleLowerCase()
          .replace(/\s/g, '');
        const { mediaCollection, memberStatus } = await getCollection(pageName);
        const result = initElementsInEditor({
          page: pageName,
          $w,
          t,
          albumData,
          galleryId:
            wixCodeApi.location.path[0] || albumData.sets.gallery[0]?.id,
          collection: mediaCollection,
          visitorStatus: memberStatus,
          coverMaxSize,
        });
        if (result) {
          const coverUrl = result.coverUrl;
          const { sets } = result;
          setPageSeo({
            wixCodeApi,
            coverUrl,
            sets,
            albumData: result.convertedAlbumData,
          });
          // SHOULD BE FIXED BY SETTING HOMA PEGA WITH DS
          if (
            sets?.length === 1 &&
            result.convertedAlbumData.template.coverPageName === 'sets' &&
            result.convertedAlbumData.createdOnServer &&
            wixCodeApi.location.path.length === 0 &&
            wixCodeApi.location.to
          ) {
            result.convertedAlbumData.albumName = '';
            result.convertedAlbumData.gogItems = [];
            wixCodeApi.location.to('/set/' + sets[0].id);
          }
        }
      }

      // important that will happen here because it does setProps
      appStateManager.registerWidget({
        name: 'headerIcon',
        setProps,
      });
      let currentSet;
      if (currentPageName === 'set') {
        currentSet = wixCodeApi.location.path[0];
      }

      setProps({
        instance,
        instanceId,
        appParams,
        goToCollectionsPage,
        isPreview: environment.isPreview,
        loggedIn: wixCodeApi.user.currentUser.loggedIn,
        collectionId: appStateManager.state.collection?.id,
        albumId: albumData.settings?.id,
        metaSiteId: albumData.settings?.metaSiteId,
        promptLogin: () => appStateManager.promptLogin({ modal: true }),
        controllerTime: Date.now(),
        currentPageName,
        currentSet,
        setPimpleVisibility: appStateManager.setPimpleVisibility,
        allowSelections: shouldAllowSelections(
          albumData.settings,
          currentPageName,
        ),
        isAlbumV8: isAlbumTemplateV8(albumData.settings),
        initElementsInEditor: initEditorElements,
        time: new Date().getTime(),
        goToPage: goToPage(controllerConfig),
      });
    },
  };
};

export default createController;
